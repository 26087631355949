<template>
  <v-snackbar
    :color="color"
    v-model="snackbar"
    :left="x === 'left'"
    :right="x === 'right'"
    :top="y === 'top'"
    :timeout="timeout"
  >
    {{snackBarText}}
    <v-btn dark text @click="snackbar = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
// 'success', 'info', 'error'
export default {
  name: "Snackbars",
  data() {
    return {
      timeout: 4000,
      left: false,
      x: "right",
      y: "top"
      // color: "success"
    };
  },
  computed: {
    ...mapGetters({
      getSnackbar: "notify/getSnackbar",
      snackBarText: "notify/getSnackBarText",
      color: "notify/getSnackBarColor"
    }),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(val) {
        this.$store.dispatch("notify/setSnackbar", val);
      }
    }
  },
  watch: {
    getSnackbar(val) {
      if (val) {
        setTimeout(() => {
          this.$store.dispatch("notify/setSnackbar", false);
        }, 4000);
      }
    }
  }
};
</script>